import React from 'react';
import { Row, Col } from 'antd';
import '../../assets/css/patrocinadores.css';

import plata_1 from '../../assets/images/anual_2024/PATROCINADORES/AMANCO.png';
import plata_2 from '../../assets/images/anual_2024/PATROCINADORES/energiareal.png';
import plata_3 from '../../assets/images/anual_2024/PATROCINADORES/engie.png';
import plata_4 from '../../assets/images/anual_2024/PATROCINADORES/finsa.png';
import plata_5 from '../../assets/images/anual_2024/PATROCINADORES/millerknoll.png';
import plata_6 from '../../assets/images/anual_2024/PATROCINADORES/steelcase.png';
import plata_7 from '../../assets/images/anual_2024/PATROCINADORES/enlight.png';
import plata_8 from '../../assets/images/anual_2024/PATROCINADORES/igsa.png';
import plata_9 from '../../assets/images/anual_2024/PATROCINADORES/mopec.png';
import plata_10 from '../../assets/images/anual_2024/PATROCINADORES/powersolutions.png';

import oro_1 from '../../assets/images/anual_2024/PATROCINADORES/aceroform.png';
import oro_2 from '../../assets/images/anual_2024/PATROCINADORES/banorte.png';
import oro_3 from '../../assets/images/anual_2024/PATROCINADORES/cortinasmex.png';
//import oro_4 from '../../assets/images/anual_2024/PATROCINADORES/cubimsa.png';
import oro_5 from '../../assets/images/anual_2024/PATROCINADORES/gg.png';
import oro_6 from '../../assets/images/anual_2024/PATROCINADORES/holcim.png';
import oro_7 from '../../assets/images/anual_2024/PATROCINADORES/mbssteel.png';
import oro_9 from '../../assets/images/anual_2024/PATROCINADORES/nh.png';
import oro_10 from '../../assets/images/anual_2024/PATROCINADORES/ppg.png';
import oro_11 from '../../assets/images/anual_2024/PATROCINADORES/arcomurray.png';
import oro_12 from '../../assets/images/anual_2024/PATROCINADORES/hfa.png';
import oro_13 from '../../assets/images/anual_2024/PATROCINADORES/soprema.png';

import titulo from '../../assets/images/reunion_anual/TITULOS/titulo_patrocinadores.png';


let logos = [
    { tipo: 1, img_src: plata_10, nombre: 'powersolutions' },
    //{ tipo: 1, img_src: plata_1, nombre: 'AMANCO' },
    { tipo: 1, img_src: plata_2, nombre: 'anergiareal' },
    { tipo: 1, img_src: plata_3, nombre: 'engie' },
    { tipo: 1, img_src: plata_7, nombre: 'enlight' },
    { tipo: 1, img_src: plata_4, nombre: 'finsa' },
    { tipo: 1, img_src: plata_8, nombre: 'igsa' },
    { tipo: 1, img_src: plata_5, nombre: 'millerknoll' },
    { tipo: 1, img_src: plata_9, nombre: 'mopec' },
    { tipo: 1, img_src: plata_6, nombre: 'steelcase' },

    //{ tipo: 2, img_src: oro_1, nombre: '' },
    { tipo: 2, img_src: oro_2, nombre: '' },
]


const CarouselPatrocinadores = (params) => {


    const plata = logos.filter(item =>{
      if(item.tipo === 1){
      return true}
      else{
      return false}
    }).map((logo,index) => {
        return(
          <Col xs={{span:12, offset:0}} md={{span:7, offset:0}}>
          <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'80%'}}  />
          </Col>
        )
    });
    const oro = logos.filter(item =>{
      if(item.tipo === 2){
      return true}
      else{
      return false}
    }).map((logo,index) => {
        return(
          <Col xs={{span:8, offset:0}} md={{span:7,offset:(logo.nombre === 'a' ? 2 : 0)}}>
          <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'80%'}}  />
         
          </Col>
        )
    });

    return (
      <>
      <Row justify="center" className='back_blanco' style={{padding:'3% 0%'}}>
        <Col xs={24} md={20}>
          <p className='textos_titulos_secciones color_azul'>PATROCINADORES</p> <br /><br />
        </Col>
        <Col xs={20}>
        <div className='patro_plata bold_font'>PLATINO</div>
            <div class="linea_2"></div>
        </Col>
        <Col xs={24} md={22}>
          <Row justify={'space-around'} align="">
            {plata}
          </Row>
        </Col>
        <Col xs={22}>
          
        </Col>
        <Col xs={20}>
          <Row align={'middle'}>
            <Col xs={24} md={24}>
            <div className='patro_oro bold_font'>ORO</div>
            <div class="linea"></div>
            </Col>
            <Col xs={0} md={0}>
            <hr  style={{border:'none', height:'4px', backgroundColor:'#A3850B', padding:'0px'}} />
            </Col>
          </Row>
          
        </Col>
        <Col xs={22} md={22}>
          <Row justify={'space-around'} align="middle">
          <Col xs={8} md={5}>
              <img src={oro_1} style={{width:'90%'}} />
            </Col>
          <Col xs={8} md={5}>
              <img src={oro_11} style={{width:'90%'}} />
            </Col>
            <Col xs={8} md={5}>
              <img src={oro_2} style={{width:'100%'}} />
            </Col>
            <Col xs={8} md={5}>
              <img src={oro_3} style={{width:'90%'}} />
            </Col>
            
            </Row>

            <Row justify={'space-around'} align="middle">
            
            <Col xs={8} md={5}>
              <img src={oro_5} style={{width:'90%'}} />
            </Col>
            <Col xs={8} md={5}>
              <img src={oro_12} style={{width:'90%'}} />
            </Col>
          <Col xs={8} md={5}>
            
              <img src={oro_6} style={{width:'90%'}} />
            </Col>
            <Col xs={8} md={5}>
              <img src={oro_7} style={{width:'90%'}} />
            </Col>
            </Row>
            <Row justify={'space-around'} align="middle">
           
          <Col xs={8} md={5}>
              <img src={oro_9} style={{width:'90%'}} />
            </Col>
            <Col xs={8} md={5}>
              <img src={oro_10} style={{width:'90%'}} />
            </Col>
            <Col xs={8} md={5}>
              <img src={oro_13} style={{width:'90%'}} />
            </Col>
            
          </Row>
        </Col>
      </Row>
      </>
    );

};

export default CarouselPatrocinadores;